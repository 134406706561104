import React from 'react';

import {
    HeroBanner,
    CaseHeader,
    Carousel,
    DeviceType,
    Testimonial,
    TextContent,
    Gallery,
    GalleryLayout,
    Team,
} from 'modules/references';
import { sendCaseStudyPageView } from 'utils/analytics';

import socialPreview from '../../../static/social-previews/mlady-zdravotnik.png';
import Seo from '../../components/SEO';

import { ReferenceCategory } from '../../constants';

const CASE_ID = 'mladyZdravotnik';
const MODULE_NAME = 'mlady-zdravotnik';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    bgBeige: '#FFF0DC',
    bgBeigeLight: '#FFF8EF',
    bgGreen: '#99E3BF',
    bgPurple: '#D8AEF9',
    testimonialBg: '#F9F9F9',
    text: '#000',
};

const team = [
    { department: 'references.case.team.management', names: ['Vojtěch Dalecký'] },
    {
        department: 'references.case.team.ux',
        names: ['Markéta Hejná'],
    },
    {
        department: 'references.case.team.design',
        names: ['Anna Meshcheryakova', 'Martin Jílek'],
    },
    {
        department: 'references.case.team.web',
        names: ['Ivan Harašta', 'Martin Foldyna', 'Jakub Baierl'],
    },
    {
        department: 'references.case.team.devops',
        names: ['Tomáš Hejátko'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Simona Bubeníková'],
    },
];

const MladyZdravotnik = () => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/hero.png`,
                    style: { height: '100%', marginTop: '1.5rem' },
                    imgStyle: { objectFit: 'contain', objectPosition: 'center' },
                },
            }}
            backgroundImage={{
                src: `${IMAGE_PATH}/hero-bg.png`,
                imgStyle: { objectFit: 'cover', objectPosition: 'top' },
            }}
            background={`${colors.bgBeige}`}
            headerTheme="dark"
            textColor={colors.text}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.DIGITALIZATION,
                outputs: [
                    { name: 'references.case.output.web', link: `case.${CASE_ID}.output.web.link` },
                    'references.case.output.ux',
                    'references.case.output.design',
                    'references.case.output.administration',
                    'references.case.output.testing',
                    'references.case.output.devops',
                ],
            }}
        />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.LITTLE_OVER_ONE_HALF}
            content={[
                {
                    background: colors.bgGreen,
                    image: {
                        src: `${IMAGE_PATH}/01-1.png`,
                        imgStyle: {
                            objectPosition: 'bottom left',
                            objectFit: 'contain',
                        },
                    },
                },
                {
                    background: colors.bgPurple,
                    image: {
                        src: `${IMAGE_PATH}/01-2.png`,
                        style: {
                            height: '85%',
                            margin: '1rem 0',
                        },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section2.name`,
                title: `case.${CASE_ID}.section2.title`,
                text: `case.${CASE_ID}.section2.text`,
            }}
        />
        <Gallery
            headerTheme="dark"
            fullHeight
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/02.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'bottom center',
                        },
                        style: {
                            maxHeight: '85%',
                            maxWidth: '85%',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/02-bg.png`,
                imgStyle: { objectFit: 'contain', objectPosition: 'center' },
            }}
            background={colors.bgBeigeLight}
        />
        <TextContent
            headerTheme="dark"
            background={colors.bgBeigeLight}
            textColor={colors.text}
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
        />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            fullHeight
            content={[
                {
                    background: colors.bgGreen,
                    image: {
                        src: `${IMAGE_PATH}/03.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'bottom right',
                        },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section4.name`,
                title: `case.${CASE_ID}.section4.title`,
                text: `case.${CASE_ID}.section4.text`,
            }}
        />
        <Carousel
            headerTheme="dark"
            deviceType={DeviceType.DESKTOP}
            background={colors.bgBeige}
            slides={[
                {
                    image: {
                        src: `${IMAGE_PATH}/04-1.png`,
                        alt: `case.${CASE_ID}.hero.project`,
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/04-2.png`,
                        alt: `case.${CASE_ID}.hero.project`,
                    },
                },
            ]}
        />
        <Testimonial
            headerTheme="dark"
            background={colors.testimonialBg}
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `${IMAGE_PATH}/testimonial.jpg`,
                    },
                },
            }}
            type="PROFILE_RIGHT"
        />

        <Team headerTheme="dark" content={team} />
    </>
);

export default MladyZdravotnik;
